import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" description="Choice Provisions was founded in 2007, making us about 417 in game industry years. We've taken the wisdom we've accrued over time and focused it towards developing the best games we can in the most sustainable way possible." lang="" meta={[]} />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
